<template>
  <div>
    <h3 style="margin:0"> Anteprima campi csv </h3>
    <p> Visualizzazione delle prime 5 righe del file</p>
    <el-alert show-icon type="info" title="Controllare la correttezza delle intestazioni e del formato dei campi prima di proseguire con l'upload" ></el-alert>

    <el-tabs v-model="activeName" v-if="fileList.length>0">
      <el-tab-pane v-for="(file, i) in fileList" :key="file" :label="file.name" :name="i+''">
        <el-table  fit v-if="csvObjects[i]" :data="csvObjects[i]" >
          <el-table-column :key="header" v-for="header in csvHeaders[i]" :prop="header" :label="header" min-width="100%"> </el-table-column>
        </el-table>
        <p v-if="csvRemainingRecords[i]>0"> altre {{csvRemainingRecords[i]}} righe caricate in memoria </p>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import FileMixin from "../mixins/FileMixin";

export default {
  name: "CsvPreview",
  props: ["fileList"],
  mixins: [FileMixin],
  data(){
    return {
      activeName: "0",
      csvObjects: [],
      csvHeaders: [],
      csvRemainingRecords: []
    }
  },
  methods:{
    // confirmDialog(){
    //   this.$emit("submit");
    //   this.showDialog = false;
    // },
    async generateTableContent() {
      this.activeName = "0"
      this.csvObjects= [];
      this.csvHeaders= [];
      this.csvRemainingRecords=[];
      for (const file of this.fileList) {
        const text = await this.readTextFile(file.raw);
        const csvObj = await this.CSVToObj(text, ";");
        const headers = Object.keys(csvObj[0]);
        this.csvHeaders.push(headers)
        const sliced = csvObj.slice(0, 5);
        this.csvObjects.push(sliced);
        const remaining = csvObj.length-sliced.length;
        if (remaining>0){
          let last= {}
          headers.forEach(el =>  last[el] = `...` )
          sliced.push(last)
        }
        this.csvRemainingRecords.push(remaining);
      }
    }
  },
  async mounted() {
    await this.generateTableContent();
  }
}
</script>

<style scoped>

</style>